.header{
    width: 1000px
    position: absolute
    top 30px
    left 50%
    transform: translateX(-50%)
    .nav{
        i{
            display: inline-block
            width: 1px
            height: 16px
            background: rgba(255,255,255,0.15);

        }
        a{
            font-size: 18px
            color: rgba(255,255,255,0.60);
        }
        a.active{
            color: #fff
        }
    }
}