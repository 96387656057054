.about{
    padding-bottom 138px
    .banner{
        height: 500px
        width: 100%
        background: url('../images/about_banner.png') no-repeat top center
        background-size: cover
        font-size: 80px
    }
    .about-title{
        height: 147px
        width: 100%
        text-align: center
        background: url('../images/title_bg.png') no-repeat top center
        background-size:auto 90px
        .tit{
           margin-top: 45px
        }
    }
    .about-content{
        margin-top 47px
    }
}